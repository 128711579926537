<template>
  <div class="ModifyOrArchivePriority">
    <CModal
      :show.sync="isPriorityModified"
      :no-close-on-backdrop="true"
      title="Priorité modifiée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre priorité a bien été modifiée 👍
      </p>
      <p>
        Vous avez bien modifié votre priorité. Vous pouvez continuer les modifications ou retourner au tableau de bord de votre mission.
      </p>
      <template #footer>
        <CButton @click="isPriorityModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/mission/'+$route.params.mission_pk)" color="dark">Retour au tableau de bord de votre mission</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isPriorityArchived"
      :no-close-on-backdrop="true"
      title="Priorité terminée"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre priorité a bien été marquée comme terminée 🥳
      </p>
      <p>
        Vous pouvez retourner au tableau de bord de votre mission.
      </p>
      <template #footer>
        <CButton @click="$router.push('/mission/'+$route.params.mission_pk)" color="primary">Retour au tableau de bord de votre mission</CButton>
      </template>
    </CModal>

    <CModal
      :show.sync="isPriorityActivated"
      :no-close-on-backdrop="true"
      title="Priorité réactivéa"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Votre priorité a bien été marquée comme en cours 👍
      </p>
      <p>
        Elle apparaîtra dans le tableau de bord mission et tous les collaborateurs affectés la verront dans
        leur liste de priorités.
      </p>
      <template #footer>
        <CButton @click="isPriorityModified = false" color="primary">Continuer les modifications</CButton>
        <CButton @click="$router.push('/mission/'+$route.params.mission_pk)" color="dark">Retour au tableau de bord de votre mission</CButton>
      </template>
    </CModal>

    <!-- REMOVE COLLABORATEUR MODAL -->
    <CModal
      :show.sync="isCollaborateurRemoving"
      :no-close-on-backdrop="true"
      title="Supprimer ce collaborateur"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          Attention, si vous supprimez {{ collaborateurRemovingFullName }} de la priorité, il ne pourra plus faire de modifications.
        </CCol>
      </CRow>
      <CRow class="mt-2">
        <CCol class="text-center">
          <CButton
            :color="removeCollaborateurButtonStyle"
            shape="pill" block class="px-4"
            @click='removeCollaborateur'
            :disabled="removeCollaborateurInProcess">
            <CSpinner size="sm" label="activate user spinner" v-if="removeCollaborateurInProcess"></CSpinner>
            {{ removeCollaborateurButtonText }}
          </CButton>
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="isCollaborateurRemoving = false" color="dark">Annuler</CButton>
      </template>
    </CModal>

    <!-- REMOVE COLLABORATEUR IS REFERENT MODAL -->
    <CModal
      :show.sync="isCollaborateurRemovingReferent"
      :no-close-on-backdrop="true"
      title="Impossible de supprimer ce collaborateur"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          {{ collaborateurRemovingFullName }} est le référent de la priorité, il n'est donc pas possible de le supprimer des
          collaborateurs associés à cette priorité.
          Pour pouvoir le supprimer, définissez un autre référent pour cette priorité
        </CCol>
      </CRow>
      <template #footer>
        <CButton @click="isCollaborateurRemovingReferent = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>
    
    <CRow>
        <CCol sm="6">
          <h1>Priorité {{priority.name}} / {{priority.mission.client.name}} - {{priority.mission.name}} </h1>
        </CCol>
        <CCol class="text-right align-self-center" sm="6">
          <CButton
            @click="goBackToMissionDashboard"
            shape="pill"
            color="outline-primary">
              <CIcon name="cil-arrow-left"/> Retour au tableau de bord mission
          </CButton>
        </CCol>
    </CRow>
    
    <CCard v-if="!priority.is_active">
      <CCardHeader>
        <slot name="header">
          <strong>Votre priorité a été marquée comme terminée</strong>
        </slot>
      </CCardHeader>

      <CCardBody>
        <CButton
          :color="activatePriorityButtonStyle"
          shape="pill" block class="px-4"
          @click='activatePriority'
          :disabled="activatePriorityInProcess">
          <CSpinner size="sm" label="activate priority spinner" v-if="activatePriorityInProcess"></CSpinner>
          {{ activatePriorityButtonText }}
        </CButton>
      </CCardBody>
    </CCard>
    
    
    
    <CCard class="mt-3">
      <CCardBody>
        <CDataTable
          class="cursor-table"
          hover
          :items="priorityCollaborateursRender"
          :fields="priorityCollaborateursRenderFields"
          :noItemsView='{ noResults: "Aucun collaborateur", noItems: "Aucun collaborateur" }'
          @row-clicked="clickOnPriorityCollaborateur"
        >
        </CDataTable>
        <CRow>
          <CCol>
            <vue-bootstrap-typeahead
            class="mt-1"
            v-model="collaborateurAddingName"
            :data="collaborateursCanBeAdded"
            :serializer="item => item.full_name"
            @hit="collaborateurAddingObject = $event"
             placeholder="Chercher votre collaborateur par nom ou prénom">
             <template slot="append">
                <CButton
                  :color="addCollaborateurButtonStyle"
                  block class="px-4"
                  @click='addCollaborateur'
                  :disabled="$v.collaborateurAddingObject.$invalid || addCollaborateurInProcess || !priority.mission.is_active">
                  <CSpinner size="sm" label="activate user spinner" v-if="addCollaborateurInProcess"></CSpinner>
                  {{ addCollaborateurButtonText }}
                </CButton>
              </template>
          </vue-bootstrap-typeahead>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>


    <CCard>
      <CCardBody>
        <CForm>

          <span> Référent actuel de la priorité : </span>
          <strong> {{actualPriorityReferentFullName}} </strong>
          <vue-bootstrap-typeahead
            class="mt-1"
            v-model="modifyPriorityReferentName"
            :data="priority.mission.collaborateurs"
            :serializer="item => item.full_name"
            @hit="modifyPriorityReferentObject = $event"
             placeholder="Chercher votre collaborateur par nom ou prénom"
          />

          <CRow class="mt-3">
            <CCol sm="6">
              <CInput
                label="Nom de la priorité"
                v-model="modifyPriorityName" type="text"
                maxlength="50"
                :disabled="!priority.is_active"
                placeholder="Nom"
                @input="$v.modifyPriorityName.$touch()"
                :isValid="$v.modifyPriorityName.$dirty ? !$v.modifyPriorityName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 50 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CSelect
                :value.sync="modifyPriorityState"
                :disabled="!priority.is_active"
                label="Etat prioritaire"
                :options="priorityStateArrayOptions"
                @input="$v.modifyPriorityState.$touch()"
                :isValid="$v.modifyPriorityState.$dirty ? !$v.modifyPriorityState.$error : null"
                custom
              />
            </CCol>
          </CRow>

          <CRow>
            <CCol sm="6">

              <div v-if="$browserDetect.isSafari">
                <b-form-group id="modify-priority-date_echeance-group" label="Date d'échéance de votre priorité" label-for="modify-priority-date_echeance-input">
                  <b-form-datepicker
                    id="modify-priority-date_echeance-input"
                    name="modify-priority-date_echeance-input"
                    locale="fr"
                    :disabled="!priority.is_active"
                    placeholder="Choisissez une date"
                    v-model="modifyPriorityEndingDate" class="mb-2"
                    @input="$v.modifyPriorityEndingDate.$touch()"
                    :state="$v.modifyPriorityEndingDate.$dirty ? !$v.modifyPriorityEndingDate.$error : null">
                  </b-form-datepicker>
                    <small class="text-danger" v-if="!$v.modifyPriorityEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </b-form-group>
              </div>
              <div v-else>
                <CInput
                  label="Date d'échéance de votre priorité"
                  v-model="modifyPriorityEndingDate" type="date"
                  placeholder="AAAA-MM-DD"
                  :disabled="!priority.is_active"
                  @input="$v.modifyPriorityEndingDate.$touch()"
                  :isValid="$v.modifyPriorityEndingDate.$dirty ? !$v.modifyPriorityEndingDate.$error : null"
                  invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                >
                <template #description>
                  <div v-if="$browserDetect.isIE" class="text-info">
                    <small>Vous utilisez Internet Explorer, veuillez entrer votre
                    date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                  </div>
                  <small class="text-danger" v-if="!$v.modifyPriorityEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </template>
                </CInput>
              </div>

            </CCol>
            <CCol sm="6">

              <div v-if="$browserDetect.isSafari">
                <b-form-group id="modify-priority-internal_ending_date-group" label="Deadline interne de votre priorité" label-for="modify-priority-internal_ending_date-input">
                  <b-form-datepicker
                    id="modify-priority-internal_ending_date-input"
                    name="modify-priority-internal_ending_date-input"
                    locale="fr"
                    :disabled="!priority.is_active"
                    placeholder="Choisissez une date"
                    v-model="modifyPriorityInternalEndingDate" class="mb-2"
                    @input="$v.modifyPriorityInternalEndingDate.$touch()"
                    :state="$v.modifyPriorityInternalEndingDate.$dirty ? !$v.modifyPriorityInternalEndingDate.$error : null">
                  </b-form-datepicker>
                  <small class="text-danger" v-if="!$v.modifyPriorityInternalEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </b-form-group>
              </div>
              <div v-else>
                <CInput
                  label="Deadline interne de votre priorité"
                  v-model="modifyPriorityInternalEndingDate" type="date"
                  placeholder="AAAA-MM-DD"
                  :disabled="!priority.is_active"
                  @input="$v.modifyPriorityInternalEndingDate.$touch()"
                  :isValid="$v.modifyPriorityInternalEndingDate.$dirty ? !$v.modifyPriorityInternalEndingDate.$error : null"
                  invalid-feedback="Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)"
                >
                <template #description>
                  <div v-if="$browserDetect.isIE" class="text-info">
                    <small>Vous utilisez Internet Explorer, veuillez entrer votre
                    date au format AAAA-MM-DD (Par exemple 1975-11-03)</small>
                  </div>
                  <small class="text-danger" v-if="!$v.modifyPriorityInternalEndingDate.validDate">Veuillez entrer votre date au bon format (dd/mm/aaaa ou aaaa-mm-dd)</small>
                </template>
                </CInput>
              </div>

            </CCol>
          </CRow>



          <CTextarea
            label="Description de la priorité"
            v-model="modifyPriorityDescription" type="text"
            rows="3"
            maxlength="300"
            :disabled="!priority.is_active"
            placeholder="Description"
            @input="$v.modifyPriorityDescription.$touch()"
            :isValid="$v.modifyPriorityDescription.$dirty ? !$v.modifyPriorityDescription.$error : null"
          >
          </CTextarea>

          <CRow v-if="priority.is_active" class="mt-4">
            <CCol sm="6">
              <CButton
                :color="modifyPriorityButtonStyle"
                shape="pill" block class="px-4"
                @click='modifyPriority'
                :disabled="$v.modifyPriorityReferentObject.$invalid || $v.modifyPriorityName.$invalid || $v.modifyPriorityState.$invalid ||  $v.modifyPriorityInternalEndingDate.$invalid ||  $v.modifyPriorityEndingDate.$invalid || $v.modifyPriorityDescription.$invalid || modifyPriorityInProcess">
                <CSpinner size="sm" label="modify priority spinner" v-if="modifyPriorityInProcess"></CSpinner>
                {{ modifyPriorityButtonText }}
              </CButton>
            </CCol>
            <CCol sm="6">
              <CButton
                :color="archivePriorityButtonStyle"
                shape="pill" block class="px-4"
                @click='archivePriority'
                :disabled="archivePriorityInProcess">
                <CSpinner size="sm" label="archive priority spinner" v-if="archivePriorityInProcess"></CSpinner>
                {{ archivePriorityButtonText }}
              </CButton>
              <small class="text-danger">Attention, si vous marquez cette priorité comme terminée, aucun collaborateur ne pourra plus la modifier</small>
            </CCol>
          </CRow>
          <CRow  v-else class="mt-4">
            <CCol class="text-center">
              <strong> Votre priorité a été marquée comme terminée</strong>
            </CCol>
          </CRow>

        </CForm>

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { APIUserConnected } from '@/api/APIUserConnected'
import { isDate } from '@/validators/validators'
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()


export default {
  name: 'ModifyOrArchivePriority',
  components: {
    Loading,
    VueBootstrapTypeahead
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      // ----------- GETTERS -------------
      isPriorityLoading: false,
      priority: {
        id: 0,
        name: '',
        priority_state: '',
        ending_date: '',
        description: '',
        is_active: '',
        created_at: '',
        collaborateurs: [],
        referent: {
          id: 0,
          full_name: '',
          user: {
            id: 0,
            first_name: '',
            last_name: '',
            username: ''
          }
        },
        mission: {
          id: 0,
          name: '',
          client: '',
          type: '',
          state: '',
          montant_ht: '',
          starting_date: '',
          ending_date: '',
          description: '',
          is_active: '',
          created_at: '',

          contact: {
            id: 0,
            first_name: '',
            last_name: '',
            full_name: '',
          },
          referent: {
            id: 0,
            full_name: '',
            user: {
              id: 0,
              first_name: '',
              last_name: '',
              username: ''
            }
          },
          collaborateurs: []
        },
      },

      priorityCollaborateursRender: [],
      priorityCollaborateursUsernamesSet: new Set([]),
      priorityCollaborateursRenderFields: [
        { key: "full_name", label: "Collaborateur", tdClass: 'ui-helper-center', sortable: true},
        { key: "email", label: "Email", tdClass: 'ui-helper-center'},
        { key: "phone_number", label: "Numéro de téléphone", tdClass: 'ui-helper-center'},
      ],

      // ---------------- MODIFY PRIORITY ------------
      isPriorityModified: false,
      modifyPriorityName: '',
      modifyPriorityState: '',
      modifyPriorityInternalEndingDate: '',
      modifyPriorityEndingDate: '',
      modifyPriorityDescription: '',

      actualPriorityReferentFullName: '',
      modifyPriorityReferentName: '',
      modifyPriorityReferentObject: {
        id: '',
        full_name: ''
      },

      priorityStateArrayOptions: [
        { label: 'Priorité majeure', value: 'MAJ' },
        { label: 'Priorité mineure', value: 'MIN' },
      ],

      modifyPriorityButtonText: 'Modifier votre priorité',
      modifyPriorityButtonStyle: 'outline-primary',
      modifyPriorityInProcess: false,

      // ---------------- ARCHIVE PRIORITY ------------
      archivePriorityInProcess: false,
      archivePriorityButtonText: "Marquer comme terminée",
      archivePriorityButtonStyle: 'outline-danger',
      isPriorityArchived: false,

      // ---------------- ACTIVATE PRIORITY ------------
      activatePriorityInProcess: false,
      activatePriorityButtonText: "Remarquer cette priorité comme en cours",
      activatePriorityButtonStyle: 'outline-primary',
      isPriorityActivated: false,

      // ---------------- ADD COLLABORATEUR -----------
      collaborateurAddingName: '',
      collaborateurAddingObject: {
        id: '',
        full_name: ''
      },

      addCollaborateurButtonText: 'Affecter un collaborateur',
      addCollaborateurButtonStyle: 'outline-dark',
      addCollaborateurInProcess: false,

      // ---------------- REMOVE COLLABORATEUR --------
      isCollaborateurRemoving: false,
      isCollaborateurRemovingReferent: false,
      collaborateurRemovingId: 0,
      collaborateurRemovingFullName: '',
      removeCollaborateurButtonText: 'Retirer ce collaborateur de la priorité',
      removeCollaborateurButtonStyle: 'outline-danger',
      removeCollaborateurInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isPriorityLoading) {
        return true
      }
      return false
    },
    collaborateursCanBeAdded () {
      var collaborateurToAddArray = []
      var missionCollaborateurs = this.priority.mission.collaborateurs
      for (var i = 0; i < missionCollaborateurs.length; i++) {
        if (!this.priorityCollaborateursUsernamesSet.has(missionCollaborateurs[i]['user']['username'])) {
          collaborateurToAddArray.push({
            'id': missionCollaborateurs[i].id,
            'full_name': missionCollaborateurs[i].full_name
          })
        }
      }
      return collaborateurToAddArray
    },
  },
  validations: {
    modifyPriorityReferentObject: {
      id: {
        required
      }
    },
    modifyPriorityName: {
      minLength: minLength(2),
      maxLength: maxLength(50),
      required
    },
    modifyPriorityClient: {
      minLength: minLength(2),
      maxLength: maxLength(50),
      required
    },
    modifyPriorityState: {
      required,
    },
    modifyPriorityInternalEndingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyPriorityEndingDate: {
      required,
      validDate(value) {
        return isDate(value)
      }
    },
    modifyPriorityDescription: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(300)
    },
    collaborateurAddingObject: {
      id: {
        required
      }
    }
  },

  created: function() {
    this.getPriority()
  },

  watch: {
    priority: function (newPriority) {
      var priorityCollabs = newPriority.collaborateurs
      this.priorityCollaborateursUsernamesSet = new Set([])
      if (priorityCollabs.length == 0) {
        this.priorityCollaborateursRender = []
      }
      else {
        var final_array = []
        for (var i = 0; i < priorityCollabs.length; i++) {
          final_array.push(
            {
              'id': priorityCollabs[i].id,
              'full_name': priorityCollabs[i].full_name,
              'email': priorityCollabs[i].user.username,
              'phone_number': priorityCollabs[i].phone_number,
            }
          )
          this.priorityCollaborateursUsernamesSet.add(priorityCollabs[i].user.username)
        }
        this.priorityCollaborateursRender = final_array
      }
    },
    modifyPriorityReferentName(newQuery) {
      if (newQuery == '') {
        this.modifyPriorityReferentObject = {
          id: '',
          full_name: ''
        }
      }
    },
    collaborateurAddingName(newQuery) {
      if (newQuery == '') {
        this.collaborateurAddingObject = {
          id: '',
          full_name: ''
        }
      }
    },
  },

  methods: {
    // ------------- Getters --------------
    getPriority () {
      this.isPriorityLoading = true
      apiUserConnected.getPriority(this.token, this.$route.params.priority_pk)
      .then((result) => {
        this.priority = result.data
        this.modifyPriorityReferentObject = {
          id: this.priority.referent.id,
          full_name: this.priority.referent.full_name
        }
        this.actualPriorityReferentFullName = this.priority.referent.full_name
        this.modifyPriorityName = this.priority.name
        this.modifyPriorityState = this.priority.priority_state
        this.modifyPriorityInternalEndingDate = this.priority.internal_ending_date
        this.modifyPriorityEndingDate = this.priority.ending_date
        this.modifyPriorityDescription = this.priority.description
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isPriorityLoading = false
      })
    },

    modifyPriority () {
      this.modifyPriorityInProcess = true
      this.modifyPriorityButtonText = "Modification en cours"
      this.modifyPriorityButtonStyle = 'secondary'
      apiUserConnected.modifyPriority(this.token, this.$route.params.priority_pk,
        this.modifyPriorityReferentObject.id, this.modifyPriorityName, this.modifyPriorityState,
        this.modifyPriorityInternalEndingDate, this.modifyPriorityEndingDate, this.modifyPriorityDescription)
      .then(() => {
        this.getPriority()
        this.isPriorityModified = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.modifyPriorityInProcess = false
        this.modifyPriorityButtonText = "Modifier votre priorité"
        this.modifyPriorityButtonStyle = 'outline-primary'
      })
    },
    archivePriority () {
      this.archivePriorityInProcess = true
      this.archivePriorityButtonText = "Archivage en cours"
      this.archivePriorityButtonStyle = 'secondary'
      apiUserConnected.archivePriority(this.token, this.$route.params.priority_pk)
      .then(() => {
        this.getPriority()
        this.isPriorityArchived = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.archivePriorityInProcess = false
        this.archivePriorityButtonText = "Marquer comme terminée"
        this.archivePriorityButtonStyle = 'outline-danger'
      })
    },

    activatePriority () {
      this.activatePriorityInProcess = true
      this.activatePriorityButtonText = "Archivage en cours"
      this.activatePriorityButtonStyle = 'secondary'
      apiUserConnected.activatePriority(this.token, this.$route.params.priority_pk)
      .then(() => {
        this.getPriority()
        this.isPriorityActivated = true
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.activatePriorityInProcess = false
        this.activatePriorityButtonText = "Réactiver cette priorité"
        this.activatePriorityButtonStyle = 'outline-primary'
      })
    },

    // -------------- ADD COLLABORATEUR
    addCollaborateur () {
      this.addCollaborateurInProcess = true
      apiUserConnected.addCollaborateurToPriority(this.token, this.$route.params.priority_pk, this.collaborateurAddingObject.id)
      .then(() => {
        this.getPriority()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.addCollaborateurInProcess = false
      })
    },

    // ------------ REMOVE COLLABORATEUR
    clickOnPriorityCollaborateur (item) {
      this.collaborateurRemovingId = item.id
      this.collaborateurRemovingFullName = item.full_name
      this.isCollaborateurRemoving = true
    },

    removeCollaborateur () {
      this.removeCollaborateurInProcess = true
      if (this.collaborateurRemovingId == this.priority.referent.id) {
        this.isCollaborateurRemoving = false
        this.removeCollaborateurInProcess = false
        this.isCollaborateurRemovingReferent = true
      }
      else {
        apiUserConnected.removeCollaborateurFromPriority(this.token, this.$route.params.priority_pk, this.collaborateurRemovingId)
        .then(() => {
          this.getPriority()
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isCollaborateurRemoving = false
          this.removeCollaborateurInProcess = false
        })
      }
    },
    
    // ---------------- Router 
    goBackToMissionDashboard () {
      this.$router.push('/mission/' + this.priority.mission.id)
    }
  }
}
</script>
