export const file_size_validation = (value) =>  {
  if (!value) {
    return true
  }
  if (value == null) {
    return true
  }
  if (value == '') {
    return true
  }
  var file = value
  return (file.size < 5* 1024 * 1024)
}

export const file_extension_validation = (value) =>  {
  if (!value) {
    return true
  }
  if (value == null) {
    return true
  }
  if (value == '') {
    return true
  }
  var file = value

  const accepted_extensions = ['pdf', 'jpg', 'png', 'jpeg', 'xls', 'xlsx', 'doc', 'docx']
  var re = /(?:\.([^.]+))?$/
  var ext = re.exec(file.name)[1]

  if (ext) {
    return (accepted_extensions.includes(ext.toLowerCase()))
  }
  else {
    return false
  }
}


export const image_extension_validation = (value) =>  {
  if (!value) {
    return true
  }
  if (value == null) {
    return true
  }
  if (value == '') {
    return true
  }
  var file = value

  const accepted_extensions = ['jpg', 'png', 'jpeg']
  var re = /(?:\.([^.]+))?$/
  var ext = re.exec(file.name)[1]

  if (ext) {
    return (accepted_extensions.includes(ext.toLowerCase()))
  }
  else {
    return false
  }
}



export const password_validation = (value) => {
  if (value.length < 8) {
    return false
  }
  var format_special_caracters = /[!@#$%^&*()_+\-=[\]{};':"|,.<>/?]/;
  var format_number = /\d/;
  var format_uppercase = /[A-Z]/;

  var contains_special_caracters = format_special_caracters.test(value)
  var contains_number = format_number.test(value);
  var contains_uppercase = format_uppercase.test(value);

  if (contains_special_caracters && contains_number && contains_uppercase) {
    return true
  }
  return false
}

export const isDate = (value) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!value.match(regEx)) return false;  // Invalid format
  var d = new Date(value);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === value;
}

export const isDateOrBlank = (value) => {
  if(value == '') return true;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!value.match(regEx)) return false;  // Invalid format
  var d = new Date(value);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === value;
}

export const isDateOrNullOrBlank = (value) => {
  if (value == null || value == '') return true;
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!value.match(regEx)) return false;  // Invalid format
  var d = new Date(value);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  return d.toISOString().slice(0,10) === value;
}

export const isValidDateBeforeToday = (value) => {
  var regEx = /^\d{4}-\d{2}-\d{2}$/;
  if(!value.match(regEx)) return false;  // Invalid format
  var d = new Date(value);
  var dNum = d.getTime();
  if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
  if(!d.toISOString().slice(0,10) === value) return false;
  var today = new Date()
  if (today < d) return false;
  return true;
}




function mod97(string) {
    var checksum = string.slice(0, 2), fragment;
    for (var offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}


export const isValidIban = (value) => {
  var CODE_LENGTHS = {
      AT: 20, BE: 16, BG: 22,
      CH: 21, CY: 28, CZ: 24, DE: 22, DK: 18, EE: 20, ES: 24,
      FI: 18, FR: 27, GB: 22, GI: 23, GR: 27, GT: 28, HR: 21,
      HU: 28, IE: 22, IS: 26, IT: 27,
      LI: 21, LT: 20, LU: 20, LV: 21, MC: 27,
      MT: 31, NL: 18, NO: 15, PL: 28, PT: 25,
      RO: 24, SE: 24, SI: 19, SK: 24, SM: 27
  };
  var iban = String(value).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
          code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
          digits;
  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
      return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
      return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1;
}


export const isValidBic = (value) => {
  var regEx = /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
  if(!value.match(regEx)) return false;  // Invalid format
  return true
}


export const isStringIntegerOrBlank = (value) => {
  if(value == '') return true;
  var value_without_spaces = value.replace(/\s/g,'');
  return /^\d+$/.test(value_without_spaces);
}


export const isClientRef = (value) => {
  var regEx = /^C-\d+$/;
  if(!value.match(regEx)) return false;  // Invalid format
  return true
}
